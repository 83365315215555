@import "/src/styles/base/main";

.header {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 999;

  .upper {
    background-color: var(--primary);
    box-shadow: 0 4px 10px 0 #2c313133;
    box-sizing: border-box;
    height: 4.5rem;
    pointer-events: all;
    width: 100%;
    z-index: 2;
  }

  .inner {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    padding: 1rem 3rem;
    width: 100%;

    @include media-breakpoint-down(lg) {
      padding: 1rem 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }

    .account-popup {
      align-items: center;
      background-color: #fff;
      border-radius: 1.5rem;
      box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      position: absolute;
      right: -1.5rem;
      top: 4rem;
      width: 17.625rem;

      p {
        word-break: break-all;
      }

      @include media-breakpoint-down(lg) {
        right: 0;
      }

      .avatar {
        align-items: center;
        background-color: #d9d9d9;
        border-radius: 100%;
        display: flex;
        height: 6.25rem;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 6.25rem;

        p {
          @include font-size(40px);
          @include rfs(60px, line-height);

          color: #9ea3a2;
          font-weight: 500;
          margin: 0;
          word-break: unset;
        }

        img {
          object-fit: cover;
          object-position: center;
        }
      }

      hr {
        color: #d1d1d2;
        margin: 1rem 0;
        width: 100%;
      }
    }
  }
}

:global(.enable-dark) .header {
  @media (prefers-color-scheme: dark) {
    .upper {
      box-shadow: 0 4px 10px 0 #e8e8e844;
    }

    img {
      filter: invert(1) saturate(0);
    }
  }
}
