.notifications-container {
  top: 6rem !important;

  > div > div {
    border-radius: 0.5rem;
    min-width: 35rem;
    padding: 0;
  }

  .notification {
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    &.success {
      background-color: #f1f8f5;
      border: 1px solid #95c9b4;
    }

    &.error {
      background-color: #fff4f4;
      border: 1px solid #d72c0d;
    }

    .icon {
      font-size: 1.25rem;
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 0.9rem;
      top: 0.9rem;
    }
  }
}
