@import "./base/main";
@import "./base/themes";

@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap-utilities";

html {
  scroll-behavior: smooth !important;
}

:root {
  html,
  body {
    font-family: $spezia;
    margin: 0;
    max-width: 100vw;
    overflow-x: hidden;
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;

    &.hover {
      background-color: transparent;
      background-image: linear-gradient(
        180deg,
        rgb(0 0 0 / 0%) 90%,
        var(--secondary) 0
      );
      background-repeat: no-repeat;
      background-size: 0 100%;
      border: 0;
      color: inherit;
      cursor: pointer;
      display: initial;
      line-break: auto;
      max-width: 100%;
      min-width: min-content;
      outline: 0;
      position: relative;
      text-decoration: none;
      transition: all 400ms ease;
      word-wrap: break-word;

      &.thin {
        background-image: linear-gradient(
          180deg,
          rgb(0 0 0 / 0%) 95%,
          var(--secondary) 0
        );
      }

      &:hover {
        background-size: 100% 100%;
      }

      &.active {
        background-size: 100% 100%;
      }
    }
  }

  input {
    font-family: $spezia;
  }

  button {
    align-items: center;
    appearance: none;
    border-radius: 6px;
    border-style: solid;
    cursor: pointer;
    display: flex;
    font-family: $spezia;
    height: fit-content;
    justify-content: center;
    white-space: nowrap;
    width: fit-content;

    .icon {
      margin: 0 0.75rem;
      transition: all 250ms ease-in-out;
    }

    &.primary {
      background-color: var(--secondary);
      border-color: var(--secondary);
      color: var(--primary);
    }

    &.secondary {
      background-color: var(--primary);
      border-color: $light-gray;
      color: var(--secondary);
    }

    &.black {
      background: $black;
      border-color: $black;
      color: $white;
    }

    &.white {
      background: $white;
      border-color: $light-gray;
      color: $midnight;
    }

    &.delete {
      background: #d74040;
      border-color: #d74040;
      color: $white;
    }

    &.alt-delete {
      background: transparent;
      border-color: transparent;
      color: #d74040;
      transition: 150ms ease;

      &:hover {
        background-color: #ffdcdc;
      }
    }

    &.text {
      background-color: transparent;
      border: 0;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  span.highlight {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 70%,
      var(--accent) 70%,
      var(--accent) 85%,
      rgb(0 0 0 / 0%) 85%,
      rgb(0 0 0 / 0%) 100%
    );
    background-repeat: no-repeat;
    background-size: 100 100%;
  }
}

.gmnoprint,
.gm-style-cc,
.gm-style img[alt="Google"] {
  display: none;
}

.red {
  color: c00 !important;
}

.white-space--nowrap {
  white-space: nowrap;
}
