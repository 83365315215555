@import "/src/styles/base/main";

.side-nav {
  display: flex;
  height: 100%;
  left: 0;
  min-width: 100%;
  pointer-events: none;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;

  .outside {
    background-color: $black;
    opacity: 0.5;
    top: 0;
    transition: opacity 1000ms ease-in-out;
    width: 100%;

    &.hide {
      opacity: 0;
    }
  }

  .menu {
    border-left: 1px solid;
    color: var(--secondary);
    height: 100%;
    position: absolute;
    right: -501px;
    top: 0;
    transition:
      background-color 1000ms ease-in-out,
      opacity 1000ms ease-in-out,
      transform 1000ms ease-in-out;
    width: 500px;

    @include media-breakpoint-between(md, lg) {
      right: calc(-1 * (50% + 1px));
      width: 50%;

      &.active {
        top: 0 !important;
      }
    }

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid;
      height: initial;
      right: 0;
      top: -1000px;
      transition: top 1000ms ease-in-out;
      width: 100%;
    }

    .nav-content {
      background-color: var(--primary);
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
      padding: 1rem 3rem;
      position: sticky;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  ul {
    flex-direction: column;
    margin: 0;
    padding-left: 0;

    li {
      display: flex;
      flex-direction: column;
      list-style-type: none;

      .menu-item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        @include media-breakpoint-down(md) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &.active {
    pointer-events: all;

    &::before {
      background: inherit;
      content: "";
      height: 150%;
      inset: -50% -50% 0 0;
      position: absolute;
      width: 150%;
      z-index: -1;
    }

    .menu {
      transform: translateX(-100%);

      @include media-breakpoint-down(md) {
        top: 0;
        transform: none;
      }
    }
  }
}
