.footer {
  align-items: center;
  border-top: 1px solid #dcdcdc;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  justify-content: center;
  padding: 3rem 1.5rem;

  .inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1440px;
    padding: 0 3rem;
    width: 100%;

    a {
      width: fit-content;
    }

    &.copyright {
      border-top: 1px solid #dcdcdc;
      margin-top: 3rem;
      padding-top: 1.5rem;

      p {
        color: #747474;
        letter-spacing: 0.0375rem;

        a {
          text-decoration-line: underline;
        }
      }
    }

    h3 {
      color: #747474;
      letter-spacing: 0.0875rem;
    }
  }
}

:global(.enable-dark) .footer {
  @media (prefers-color-scheme: dark) {
    img {
      filter: invert(1) saturate(0);
    }
  }
}
