@import "/src/styles/abstracts/variables";

:root {
  --accent: #d5faf8;
  --primary: #{$white};
  --primary-rgb: 255 255 255;
  --secondary: #{$black};
  --secondary-rgb: 23 30 45;
  --tertiary: #{$gray};
  --color-scheme: normal;

  background-color: var(--primary);
  border-color: var(--secondary);
  color: var(--secondary);
  color-scheme: var(--color-scheme);
  font-family: sans-serif;

  ::selection {
    background: var(--accent);
    color: var(--secondary);
  }
}

:root.enable-dark {
  @media (prefers-color-scheme: dark) {
    --accent: #9cf0eb;
    --primary: #{$black};
    --primary-rgb: 23 30 45;
    --secondary: #{$white};
    --secondary-rgb: 255 255 255;
    --tertiary: #{$white};
    --color-scheme: dark;
  }
}
