#hamburger-button {
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  height: 17px;
  opacity: 1;
  overflow: hidden;
  padding: 0;
  pointer-events: all;
  position: relative;
  transition: opacity 200ms ease;
  width: 24px;
  z-index: 11;

  &:focus {
    outline: none;
  }

  span {
    background: var(--secondary);
    border-radius: 9px;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: 250ms ease-in-out;
    width: 100%;

    &:nth-child(1) {
      left: 12.5%;
      top: 0;
      width: 75%;
    }

    &:nth-child(2) {
      top: calc(50% - 1px);
    }

    &:nth-child(3) {
      top: calc(50% - 1px);
    }

    &:nth-child(4) {
      left: 12.5%;
      top: calc(100% - 2px);
      width: 75%;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        left: 50%;
        top: calc(50% - 1px);
        width: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        left: 50%;
        top: calc(50% - 1px);
        width: 0;
      }
    }
  }
}
